<template>
  <div class="container">
    <div class="title">粗门俱乐部装备社用户协议</div>
    <div>发布时间：2023 年 06 月 01 日</div>
    <div>生效时间：2023 年 06 月 01 日</div>
    <div>欢迎使用粗门俱乐部装备社服务！（下称本服务）。</div>
    <div><b>如果您通过任何形式和途径使用本服务，即表明您已经阅读、理解、同意粗门各类相关服务协议、隐私政策、权利声明等使用规范或条件，并愿意接受粗门各类协议的约束。</b>根据上述规范，并结合本服务的特点，粗门制定了《粗门俱乐部装备社用户协议》（下称本协议），作为一般服务协议的特别规定，如果本协议的条款与其他协议有不一致之处，则以本协议为准。</div>
    <div class="nav">
      第一条 帐户安全
      <br>
      如果您使用本服务，您理解并同意将以超出一般服务的严格态度维护和管理您的帐户和密码的保密性并限制第三方使用/访问您的计算机或移动设备，并且，您明确同意对在您的帐户和密码下发生的所有活动承担责任。<br />
      粗门仅向具有完全民事能力的成年人提供本服务。如果您不具备完全民事行为能力，您应当在父母或监护人的参与下使用本服务。<br />
      如果您代表单位使用本服务，您确认，您已取得单位的合法书面授权来使用本网站。如果您不符合前述条件，粗门将不能为您提供服务。因此给粗门或者第三方造成损失的，您须承担相应的赔偿责任。<br />
    </div>

    <div class="nav">
      第二条 服务内容 
      <br>
      如果您通过任何形式和途径使用本服务，您将成为粗门装备社的经销商，您应当遵守并执行粗门或品牌方的各类经销商职责。<br>
      您有权在您运营的用户群中分享商品销售链接，并获取一定的服务费。<br>
      您应当维护粗门平台市场良性竞争秩序，不得贬低、诋毁竞争对手，不得干扰粗门平台上进行的任何交易、活动，不得以任何不正当方式提升或试图提升自身的信用度，不得以任何方式干扰或试图干扰粗门平台的正常运作。<br>
    </div>

    <div class="nav">
      第三条 经销商职责 
      <br>
      1.1.您作为粗门的经销商，应积极销售粗门的商品，发展潜在消费人群和扩大市场占有率。<b>未经粗门事先书面批准，您不得擅自设立、发展、授权下级经销商，或以任何其他形式授权其他方（包括公司或自然人）经营粗门品牌或销售粗门代理品牌商品，您不得转让、出借、抵押其依据本合同获得的销售商品专卖权。</b>您违反前述政策的，粗门有权随时单方终止您经销商的资格，且粗门因此遭受的任何损失，您应承担全部赔偿责任。<br>
      1.2.您仅限于在授权范围内销售粗门品牌或粗门代理品牌的商品，您不得将商品以任何方式在经销商授权范围之外营销和销售，不得擅自通过互联网、电视和电话网络、实体店铺等方式销售指定商品。如您出现违反本条约定的行为，粗门有权要求立即终止您经销商的资格，并有权要求您赔偿粗门因此遭受的全部损失。<br>
      1.3.您应当按粗门提供的营销材料及相关要求对商品进行介绍和推广。尽管如此，您应当自行负责确保对商品或服务的介绍和推广真实、准确、完整和清楚，符合市场竞争、广告、隐私和信息安全及消费者权益保护等相关法律要求。如粗门提供的营销材料与法律要求不符，经销商应当及时向粗门反馈。<br>
      1.4.未经粗门事先同意，您不得擅自获取和使用非由粗门提供和允许您使用的粗门营销材料的部分或全部，包括但不限于商品图片及影音文件等。对于粗门提供的营销材料，您应当按照粗门的指示进行使用，未经粗门事先书面同意，您不得擅自进行修改，也不得用于核准范围之外的任何目的。同时，您应当采取合理的保护措施，避免他人盗用粗门营销材料。如您发现第三方盗用的，应当及时通知粗门，并协助粗门进行处理。<br>
      1.5.依法纳税是每一个公民、企业应尽的义务，您应对销售额/营业额超过法定免征额部分及时、足额地向税务主管机关申报纳税。
    </div>

    <div class="nav">
      第四条 本服务的终止 
      <br>
      粗门有权根据自身经营情况终止某一或全部商品的销售，并依据《粗门电商平台服务协议》第八条的所列的方式通知您终止本服务。
    </div>

    <div class="nav">
      第五条 其他 
      <br>
      1.1.本协议未约定事宜，以粗门已发布及将来发布的各类服务协议、规则为准。<br>
      1.2.本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
    </div>

    <div class="nav">小象蓝图（成都）科技有限公司</div><br>
  </div> 
</template>

<script>
export default {

}
</script>

<style lang="scss">
.container {
  padding: 10px;

  div {
    font-size: 15px;
    line-height: 1.6;
  }

  .title {
    font-size: 26px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .nav {
    margin-top: 24px;
  }
}
</style>
